<template>
	<div class="orderDetails">
		<div class="orderDetails_box">
			<div class="title pointer flex-center">
				<span @click="goBtn(1)">首页 > </span>
				<span @click="goBtn(2)"> 我的订单 > </span> 订单详情
			</div>
			<div class="step_box display" v-if="orderData.tradeStatus < 6">
				<div class="step_list flex-center">
					<div class="step steps display column">
						<img src="./img/step1s.png" alt="" />
						<div class="setp_details nowrap">提交订单</div>
						<div class="setp_time nowrap">{{ orderData.submitTime }}</div>
					</div>
					<div
						class="step_line"
						:class="orderData.tradeStatus >= 2 ? 'step_lines' : ''"
					></div>
				</div>
				<div class="step_list flex-center">
					<div class="step display column">
						<img
							src="./img/step2s.png"
							alt=""
							v-if="orderData.tradeStatus >= 2"
						/>
						<img src="./img/step2.png" alt="" v-else />
						<div class="setp_details nowrap">付款成功</div>
						<div class="setp_time nowrap">{{ orderData.paymentTime }}</div>
					</div>
					<div
						class="step_line"
						:class="orderData.tradeStatus >= 3 ? 'step_lines' : ''"
					></div>
				</div>

				<div class="step_list flex-center">
					<div class="step display column">
						<img
							src="./img/step4s.png"
							alt=""
							v-if="orderData.tradeStatus >= 3"
						/>
						<img src="./img/step4.png" alt="" v-else />
						<div class="setp_details nowrap">等待收货</div>
						<div class="setp_time nowrap">{{ orderData.receiveTime }}</div>
					</div>
					<div
						class="step_line"
						:class="orderData.tradeStatus >= 4 ? 'step_lines' : ''"
					></div>
				</div>
				<div class="step_list flex-center">
					<div class="step display column">
						<img
							src="./img/step5s.png"
							alt=""
							v-if="orderData.tradeStatus >= 4"
						/>
						<img src="./img/step5.png" alt="" v-else />
						<div class="setp_details nowrap">完成</div>
						<div class="setp_time nowrap">{{ orderData.receiveTime }}</div>
					</div>
				</div>
			</div>
			<div class="order_info_box flex">
				<div class="order_info_fl">
					<div class="order_info">订单详情</div>
					<div class="order_center">
						<div class="list lists">
							收货地址：{{ orderData.receiveName }},
							{{ orderData.receivePhone }},{{ orderData.receiveAddress }}
						</div>
						<div class="list">订单编号：{{ orderData.orderCode }}</div>
						<div class="list">卖家留言：{{ orderData.buyerWord }}</div>
					</div>
				</div>
				<div class="order_info_fr flex">
					<img class="hint" src="./img/hint.png" alt="" />
					<div class="fr_content">
						<div class="order_state flex">
							订单状态:
							<div v-if="orderData.tradeStatus == 1">待付款，等待买家付款</div>
							<div v-if="orderData.tradeStatus == 2">待发货，等待商家发货</div>
							<div v-if="orderData.tradeStatus == 3">
								商家已发货，等待买家确认
							</div>
							<div v-if="orderData.tradeStatus == 4">待评价</div>
							<div v-if="orderData.tradeStatus == 5">已完成</div>
							<div v-if="orderData.tradeStatus == 6">已取消</div>
							<div v-if="orderData.tradeStatus == 7">退换申请中</div>
							<div v-if="orderData.tradeStatus == 8">退换通过</div>
							<div v-if="orderData.tradeStatus == 9">退换失败</div>
						</div>
						<!-- <div class="order_time">您还有8天4小时51分13秒;来确认收货,超时订单自动确认收货</div> -->
						<div
							class="logistics_box flex"
							v-if="
								orderData.tradeStatus != 1 &&
								orderData.tradeStatus != 2 &&
								orderData.tradeStatus != 6 &&
								orderData.tradeStatus != 7
							"
						>
							<div class="logistics">物流：{{ orderData.trackName }}</div>
							<div class="logistics">
								包裹运单号:{{ orderData.trackNumber }}
							</div>
						</div>
						<div class="Logistics_info">
							<div
								class="list"
								v-for="(item, index) in logisticsData"
								:key="index"
							>
								{{ item.time }}{{ item.context }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="goods_box">
				<div class="head_box flex-center">
					<div class="head_nav_name head_nav">商品</div>
					<div class="head_nav_name head_nav_s">单价(元)</div>
					<div class="head_nav_name head_nav_ss">数量</div>
					<div class="head_nav_name head_nav_sss">状态</div>
				</div>
				<div
					class="goodsList_box flex"
					v-for="(item, index) in orderData.orderItemVOS"
					:key="index"
				>
					<div class="goodsList flex">
						<div class="goodsImg">
							<img :src="item.goodsUrl" alt="" />
						</div>
						<div class="goodsName_gg">
							<div class="goodsName row">{{ item.goodsName }}</div>
							<div class="goods_gg">{{ item.skuTitle }}</div>
						</div>
					</div>
					<div class="goodsList_ss">
						￥{{ Number(item.unitPrice).toFixed(2) }}
					</div>
					<div class="goodsList_sss">{{ item.goodsNum }}</div>
					<div class="goodsList_ssss">
						<div v-if="item.isRefund != 0">
							<div v-if="item.isRefund == 0">未退换</div>
							<div v-if="item.isRefund == 1">退换</div>
							<div v-if="item.isRefund == 2">撤销退换</div>
						</div>
						<div v-else>
							<div class="orderState" v-if="orderData.tradeStatus == 1">
								待付款
							</div>
							<div class="orderState" v-if="orderData.tradeStatus == 2">
								待发货
							</div>
							<div class="orderState" v-if="orderData.tradeStatus == 3">
								待确认收货
							</div>
							<div v-if="orderData.tradeStatus == 4">待评价</div>
							<div v-if="orderData.tradeStatus == 5">已完成</div>
							<div v-if="orderData.tradeStatus == 6">已取消</div>
							<div v-if="orderData.tradeStatus == 7">退换申请</div>
							<div v-if="orderData.tradeStatus == 8 && item.isRefund == 1">
								退换通过
							</div>
							<div v-if="orderData.tradeStatus == 9">退换失败</div>
						</div>
						<!-- <div class="orderTime">5天4时</div> -->
					</div>
				</div>
				<div class="totalPrice_box flex">
					<div class="price_box">
						<div class="price_list flex">
							<div class="label">商品总价：</div>
							<div class="price totalPrice">
								￥{{ Number(orderData.sumBasePrice).toFixed(2) }}
							</div>
						</div>
						<div class="price_list flex">
							<div class="label">优惠券：</div>
							<div class="price totalPrice">
								￥{{ Number(orderData.couponMoney).toFixed(2) }}
							</div>
						</div>
						<div class="price_list flex">
							<div class="label">积分：</div>
							<div class="price totalPrice">
								￥{{ orderData.integralMoney.toFixed(2) }}
							</div>
						</div>
						<div class="price_list flex">
							<div class="label">运费(快递)：</div>
							<div class="price totalPrice">
								￥{{ Number(orderData.freight).toFixed(2) }}
							</div>
						</div>
						<div class="price_list flex">
							<div class="label realPay_label">实付款：</div>
							<!-- UI:不管订单什么状态，实付款都必须是订单总金额 -->
							<!-- <div class="price realPayPrice" v-if="orderData.tradeStatus == 6">待付款</div> -->
							<div class="price realPayPrice">
								￥{{ Number(orderData.sumSalePrice).toFixed(2) }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="guess_you_like_box">
				<div class="guess_you_like Between">
					<div class="fl">猜你喜欢</div>
					<div class="fr flex">
						<!-- <div class="num display numActive">1</div> -->
					</div>
				</div>
				<GoodsList :goodsList="goodsList.slice(0, 5)" />
			</div>
		</div>
	</div>
</template>

<script>
import GoodsList from '../mall/components/list.vue'
export default {
	components: {
		GoodsList,
	},
	data() {
		return {
			orderData: {},
			goodsList: [],
			params: {
				orderId: '',
				orderCode: '',
				token: this.$store.state.userData.token,
			},
			logisticsData: [],
		}
	},
	mounted() {
		this.params.orderId = this.$route.query.orderId
		this.params.orderCode = this.$route.query.orderCode
		this.getOrderDetails()
		this.getRecommendGoods()
	},
	methods: {
		// 获取订单详情
		getOrderDetails() {
			this.api.findUserOrderDetails(this.params).then((res) => {
				if (res.code == 0) {
					this.orderData = res.data
					if (
						this.orderData.trackName != undefined &&
						this.orderData.trackName != ''
					) {
						this.getLogistics()
					}
				}
			})
		},
		// 获取推荐商品
		getRecommendGoods() {
			this.api.findRecommendGoods().then((res) => {
				if (res.code == 0) {
					this.goodsList = res.data
				}
			})
		},
		//获取物流信息
		getLogistics() {
			let params = {
				token: this.$store.state.userData.token,
				trackName: this.orderData.trackName,
				trackNumber: this.orderData.trackNumber,
			}

			this.api.viewLogistics(params).then((res) => {
				if (res.code == 0) {
					this.logisticsData = res.data.data
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		goBtn(type) {
			if (type == 1) {
				this.$router.push('/')
			} else {
				let data = {
					isShow: 99,
				}
				sessionStorage.setItem('broadside', JSON.stringify(data))
				this.$store.commit('getBroadside', data)
				this.$router.push({
					path: '/person/index',
					query: {
						type: '',
					},
				})
			}
		},
	},
}
</script>
<style lang="scss" scoped >
.orderDetails {
	width: 1200px;
	margin: 0 auto;
	margin-top: 20px;
	.orderDetails_box {
		.title {
			font-size: 12px;
			color: #666666;
		}
		.step_box {
			padding: 0px 27px;
			box-sizing: border-box;
			margin-top: 9px;
			.step_list {
				position: relative;
				.steps:nth-child(1) {
					margin-left: 0px !important;
				}
				.step {
					margin-left: -40px;
					img {
						width: 42px;
						height: 42px;
					}
					.setp_details {
						font-size: 12px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #666666;
						margin-top: 8px;
					}
					.setp_time {
						width: 120px;
						height: 10px;
						font-size: 12px;
						color: #666666;
						margin-top: 8px;
					}
				}
				.step_line {
					width: 212px;
					height: 1px;
					background: #727272;
					margin-top: -40px;
					margin-left: -40px;
				}
				.step_lines {
					background: #7bbc52 !important;
				}
			}
		}
		.order_info_box {
			height: 288px;
			background: #fff;
			border: 1px solid #eaeaea;
			margin-top: 34px;
			.order_info_fl {
				width: 274px;
				background: white;
				border-right: 1px solid #eaeaea;
				.order_info {
					width: 274px;
					height: 36px;
					line-height: 36px;
					font-size: 14px;
					color: #666666;
					background: #d0d0d0;
					padding-left: 20px;
					box-sizing: border-box;
					margin-top: -1px;
				}
				.order_center {
					width: 274px;
					padding: 20px;
					box-sizing: border-box;
					.list {
						font-size: 12px;
						color: #666666;
						margin-top: 26px;
					}
					.lists {
						margin-top: 0px !important;
					}
				}
			}
			.order_info_fr {
				width: 925px;
				padding: 34px 21px 27px 55px;
				.hint {
					width: 32px;
					height: 32px;
				}
				.fr_content {
					margin-top: 7px;
					margin-left: 17px;
					.order_state {
						font-size: 16px;
						color: #333;
					}
					.order_time {
						font-size: 12px;
						color: #333;
						margin-top: 10px;
					}
					.logistics_box {
						font-size: 12px;
						color: #333;
						margin-top: 8px;
						.logistics {
							margin-right: 20px;
						}
					}
					.Logistics_info {
						width: 792px;
						height: 126px;
						overflow: hidden;
						overflow-y: scroll;
						margin-top: 27px;
						.list {
							font-size: 12px;
							color: #666666;
							margin-bottom: 5px;
						}
					}
				}
			}
		}
		.goods_box {
			background: white;
			margin-top: 21px;
			border: 1px solid #eaeaea;
			box-sizing: border-box;
			.head_box {
				width: 100%;
				height: 40px;
				background: #f4f4f4;
				.head_nav_name {
					font-size: 12px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #666666;
				}
				.head_nav {
					margin-left: 124px;
				}
				.head_nav_s {
					margin-left: 400px;
				}
				.head_nav_ss {
					margin-left: 172px;
				}
				.head_nav_sss {
					margin-left: 209px;
				}
			}
			.goodsList_box {
				height: 120px;
				padding: 20px 24px;
				box-sizing: border-box;
				border-bottom: 1px solid #eaeaea;
				.goodsList {
					.goodsImg {
						width: 78px;
						img {
							width: 78px;
							height: 78px;
						}
					}
					.goodsName_gg {
						margin-left: 14px;
						.goodsName {
							width: 334px;
							font-size: 12px;
							color: #333333;
							margin-top: 2px;
						}
						.goods_gg {
							font-size: 12px;
							color: #888888;
							margin-top: 19px;
						}
					}
				}
				.goodsList_ss {
					width: 100px;
					font-size: 14px;
					color: #666666;
					margin-left: 97px;
					margin-top: 11px;
				}
				.goodsList_sss {
					width: 30px;
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #333333;
					margin-top: 11px;
					margin-left: 120px;
				}
				.goodsList_ssss {
					font-size: 14px;
					color: #666666;
					margin-top: 11px;
					margin-left: 200px;

					.orderState {
						// margin-top: -35px;
					}
					.orderTime {
						color: #e1251b;
						margin-top: 5px;
					}
				}
			}
			.totalPrice_box {
				width: 100%;
				justify-content: flex-end;
				padding-right: 31px;
				padding-top: 22px;
				box-sizing: border-box;
				.price_box {
				}
				.price_list {
					margin-bottom: 10px;
					.label {
						width: 69px;
						font-size: 12px;
						color: #333333;
						text-align: right;
					}
					.price {
						margin-left: 57px;
						font-size: 12px;
						color: #333333;
					}
					.realPayPrice {
						font-size: 18px;
						color: #e62129;
					}
					.realPay_label {
						color: #e62129;
						margin-top: 5px;
					}
				}
			}
		}
		.guess_you_like_box {
			margin-top: 57px;
			margin-bottom: 30px;
			.guess_you_like {
				padding-right: 28px;
				margin-bottom: 20px;
				.fl {
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #333333;
				}
				.fr {
					.num {
						width: 23px;
						height: 23px;
						background: #d0d0d0;
						border-radius: 50%;
						margin-left: 10px;
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #ffffff;
					}
					.numActive {
						background: #e62129 !important;
					}
				}
			}
		}
	}
}
</style>
