<template>
    <div class="goodsList_box flex flex-wrap">
        <div class="list pointer" v-for="(item,index) in goodsList " :key="index" @click="toDetails(item.id)">
            <div class="goodsImgBox display">
                <img :src="item.coverUrl" alt="">
            </div>
            <div class="goodsName astrict" v-html="item.productName"></div>
            <div class="goodsPrice">￥{{Number(item.price).toFixed(2)}}</div>
            <div class="btn_box flex">
                <div class="btn collect display pointer"  @click.stop="collectBtn(item)">
                   <img :src="item.isCollect == 1?collect_y:collect_n" alt="">{{item.isCollect ==1?'已收藏':'收藏'}} 
                </div>
                <div class="btn cart display pointer" @click.stop="toDetails(item.id)">
                   <img src="@/assets/img/goodsDetails/cart.png" alt=""> 购买
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
     props:{
        goodsList:{
            type:Array
        }
    },
    data(){
        return{
            collect_n:require('@/assets/img/goodsDetails/collect_n.png'),
            collect_y:require('@/assets/img/goodsDetails/collect_y.png'),
        }
    },
    methods:{
        //收藏
        collectBtn(item){
            this.$emit('collectBtn',item)
        },
        //去详情
        toDetails(id){
            let routeData = this.$router.resolve({
                path: "/mall/goodsDetails",
                query: {
                    id: id
                }
            });
            window.open(routeData.href, '_blank');

        },
        
    }
}
</script>
<style lang="scss" scoped>
    .goodsList_box{
        .list:nth-child(5n+1){
            margin-left: 0px;
        }
        .list:hover{
            border: 1px solid #E62129;
            box-sizing: border-box;
            .goodsName{
                color:  #E62129 !important;
            }
        }
        .list{
            width: 228px;
            height: 360px;
            background: #ffff;
             border: 1px solid #ffff;
            padding: 0px 22px;
            box-sizing: border-box;
            margin-left: 15px;
            margin-bottom: 10px;
            .goodsImgBox{
                width: 100%;
                margin-top: 25px;
                img{
                    width: 168px;
                    height: 168px;
                }
            }
            .goodsName{
                width: 170px;
                height: 33px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                line-height: 18px;
                margin-top: 18px;
            }
            .goodsPrice{
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #E1251B;
                margin-top: 16px;
                margin-bottom: 19px;
            }
            .btn_box{
                .btn{
                    width: 84px;
                    height: 36px;
                    border-radius: 3px;
                    font-size: 14px;
                    color: #333333;
                    img{
                        width: 17px;
                        height: 17px;
                        margin-right: 5px;
                    }
                }
                .collect{
                    border: 1px solid #D0D0D0;
                }
                .cart{
                    background: #E1251B;
                    color: #fff;
                    margin-left: 14px;
                }
            }
        }
    }
</style>